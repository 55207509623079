<div style="height: calc(100% - 70px)">
    <google-map #map="googleMap"
    *ngIf="apiLoaded && order"
    [center]="latestLocationCoordinates"
    [options]="mapOptions"
    height="100%"
    width="100%"
    (zoomChanged)="onZoomChange()"
    >
        <map-advanced-marker #originLocationMarker="mapAdvancedMarker"
            *ngIf="origin?.coordinates"
            [title]="origin?.type"
            [position]="origin?.coordinates"
            [content]="origin?.pin.element"
            (mapClick)="openStopLocationInfo(originLocationMarker, origin)"
        />
        <map-advanced-marker #destinationLocationMarker="mapAdvancedMarker"
            *ngIf="destination?.coordinates"
            [title]="destination?.type"
            [position]="destination?.coordinates"
            [content]="destination?.pin.element"
            (mapClick)="openStopLocationInfo(destinationLocationMarker, destination)"
        />
        <map-advanced-marker #latestLocationMarker="mapAdvancedMarker"
            *ngIf="latestLocationCoordinates"
            title="Last reported location"
            [position]="latestLocationCoordinates"
            (mapClick)="openLatestLocationInfo(latestLocationMarker)"
            [content]="latestLocationPin.element"
        />
        <map-info-window #locationInfoWindow="mapInfoWindow" />
        <map-circle 
            *ngIf="uncertaintyCircleSizeInPixels > 5"
            [center]="latestLocationCoordinates"
            [radius]="uncertaintyRadiusMeters"
            [options]="uncertaintyCircleOptions"
        />
    </google-map>
</div>