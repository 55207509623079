import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MacropointOrderTrackingPageComponent } from './macropoint-order-tracking-page.component'


const routes: Routes = [{
  path: '',
  component: MacropointOrderTrackingPageComponent,
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MacropointOrderTrackingRoutingModule { }
