import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MacropointOrderTrackingPageComponent } from './macropoint-order-tracking-page.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MacropointOrderTrackingRoutingModule } from './macropoint-order-tracking-page-routing.module';

@NgModule({
  declarations: [MacropointOrderTrackingPageComponent],
  providers: [],
  imports: [
    CommonModule,
    GoogleMapsModule,
    MacropointOrderTrackingRoutingModule,
  ],
})
export class MacropointOrderTrackingModule {}
